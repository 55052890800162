import React,{useState} from "react";
import { motion } from "framer-motion";
import ReactHtmlParser from "react-html-parser";
import './style/blogcard.scss';
import "./styles.scss"
import axios from 'axios';
import About from '../src/components/about'
import SeeNext from '../src/components/seenext'
import { Link, useHistory } from 'react-router-dom'

// import { ProgressRound } from "./components/progressround";
export function Item({ id,items,authors}) {

let {slug, categories, title, photo,content,publishDate,description} = items.find(item => item.slug === id);

const [formData, setFormData] = useState({});
const handleChange = (event) => {
    setFormData({...formData, [event.target.id]: event.target.value});
  }

  const history = useHistory();
  const routeChange = (path) => {
      history.push(path);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // submit to the DB
    try {
        // Prevent the default reloading of page
        event.preventDefault();

        const res = await axios.post("https://yujinojimabackend.onrender.com/subscribe", formData);
        
        if (res !== undefined){
            // And send the user to the home page
            routeChange(`/subscribed`);
        }
    }
    catch (error) {
    }
}
// var prevVal = $(window).scrollTop();
// $(window).on("scroll", function(event) {
//     var elem = $(".card-content");
//     if($(window).scrollTop() > prevVal) {
//         if (elem[0].scrollHeight - elem.scrollTop() != elem.outerHeight()) {
//             $(window).scrollTop(prevVal);
//         }
//     }
//     prevVal = $(window).scrollTop();
// });

  return (
    <>
    {/* <ProgressRound/> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        transition={{ duration: 0.02, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay"
      >
        <Link to="/blogs" />
      </motion.div>
          
      {/* container pop */}
      <div className="card-content-container open">
        <motion.div className="card-content" layoutId={`card-container-${slug}`}>

          {/* image pop */}
          <motion.div
            className="card-image-container"
            layoutId={`card-image-container-${slug}`}
          >
            <img className="card-image" src={photo} alt="" />
          </motion.div>
          {/* image pop end*/}


          {/* title pop */}
          <motion.div
            className="title-container"
            layoutId={`title-container-${slug}`}
          >
            <span className="category">{categories}</span>
            <h2>{title}</h2>
            <p className="author" style={{color:'white'}}>{authors.emoji}{authors.fullName}</p>

          </motion.div>
          {/* title pop end */}


          {/* content pop */}
          <motion.div className="content-container" animate>
          <meta name="description" content={description}/>
            <div className="contain">
                <div className="textcontain">
                <h1 className="blogtitle underline" style={{backgroundImage: `linear-gradient(${authors.colour} 0%, ${authors.colour} 100%)`}}>{title}</h1>
                <p className="author">{authors.emoji}{authors.fullName}</p>
                <p className="pubdate">📅{publishDate}</p>

                <div className="bodybody">{ReactHtmlParser(content)}               
               
                
                </div>

                </div>

            </div>
          </motion.div>
          {/* content pop end */}
          <div className="footerBlog">
            <h1 className="footerTitle">Whats next?</h1>
            <div className="highlighted" style={{height:"10px",background:"var(--main-color)"}}/>
            
            <div>
              <div className="theAuthor">
                <About author={authors}/>
              </div>
              <SeeNext allblogs={items} items={slug}/>

            </div>
          </div>
          <div className="subscribeBlog">
            <h1 className="subscribeTitle">Subscribe</h1>
            <div className="highlighted" style={{height:"10px",background:"var(--main-color)",maxWidth: '600px'}}/>
          
          <h1 className="subscribeNote">here i can personally write u emails once I've uploaded teehee</h1>
          
          <form method="post" onSubmit={handleSubmit}>

              <h1>your alias</h1>
              <input type="text" placeholder="Nemo" id="name" name="name" onChange={handleChange}/><br/>
              <h1>your mail</h1>
              <input type="text" placeholder="P Sherman 42 Wallaby Way @ fishmail . com" id="mail" name="mail" onChange={handleChange}/><br/>

              <h1>your nice comment :P</h1>
              <input type="text" placeholder="Send me a cute dog" id="comment" name="comment" onChange={handleChange}/><br/>
              <br/>
              <h1>keep me updated</h1>
              <input className="tick" type="checkbox" id="notif" name="notif" value="True" onChange={handleChange}/>on uploads :)
              <br/>
              <input className="subButton" type="submit" value="Join the grind" />
            
          </form>
          </div>

        </motion.div>
      </div>
      {/* container pop end */}

    </>
  );
}

