import React from "react";
import { motion } from "framer-motion";
import ReactHtmlParser from "react-html-parser";
import './style/blogcard.scss';
import { Link } from "react-router-dom";
import "./styles.scss"
import About from './components/about'
// import SeeNext from './components/seenext'
// import { ProgressRound } from "./components/progressround";
export function ItemF({ id,items,authors}) {

let {slug, categories, title, photo,content,publishDate,description} = items;

// var prevVal = $(window).scrollTop();
// $(window).on("scroll", function(event) {
//     var elem = $(".card-content");
//     if($(window).scrollTop() > prevVal) {
//         if (elem[0].scrollHeight - elem.scrollTop() != elem.outerHeight()) {
//             $(window).scrollTop(prevVal);
//         }
//     }
//     prevVal = $(window).scrollTop();
// });

  return (
    <>
    {/* <ProgressRound/> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        transition={{ duration: 0.02, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay"
      >
        <Link to="/blogs" />
      </motion.div>
          
      {/* container pop */}
      <div className="card-content-container open">
        <motion.div className="card-content" layoutId={`card-container-${slug}`}>

          {/* image pop */}
          <motion.div
            className="card-image-container"
            layoutId={`card-image-container-${slug}`}
          >
            <img className="card-image" src={photo} alt="" />
          </motion.div>
          {/* image pop end*/}


          {/* title pop */}
          <motion.div
            className="title-container"
            layoutId={`title-container-${slug}`}
          >
            <span className="category">{categories}</span>
            <h2>{title}</h2>
            <p className="author" style={{color:'white'}}>{authors.emoji}{authors.fullName}</p>

          </motion.div>
          {/* title pop end */}


          {/* content pop */}
          <motion.div className="content-container" animate>
          <meta name="description" content={description}/>
            <div className="contain">
                <div className="textcontain">
                <h1 className="blogtitle underline" style={{backgroundImage: `linear-gradient(${authors.colour} 0%, ${authors.colour} 100%)`}}>{title}</h1>
                <p className="author">{authors.emoji}{authors.fullName}</p>
                <p className="pubdate">📅{publishDate}</p>

                <div className="bodybody">{ReactHtmlParser(content)}                
                
                {/* <SeeNext allblogs={items} items={slug}/> */}
                
                </div>

                </div>

            </div>
            
          </motion.div>
          
          {/* content pop end */}
          <About author={authors}/>
        </motion.div>
      </div>
      {/* container pop end */}

    </>
  );
}

