import React from 'react';
import { motion, useViewportScroll, useTransform  } from 'framer-motion';
import '../style/backback.scss'
import '../style/model.scss'

export default function BackBack() {
    const { scrollYProgress } = useViewportScroll();
    const scale = useTransform(scrollYProgress, [0, 0.2, 1], [0, 0, 1]);
    return (
        <div className="detailth">
        <motion.div className="backback"
        style={{opacity:scale}}>
            <motion.div className='wet'>
              <motion.span className='first'>
                <motion.span >Y</motion.span >
                <motion.span >U</motion.span>
                <motion.span >J</motion.span>
                <motion.span >I</motion.span>
              </motion.span>
              <motion.span className='middle'>
              
              <motion.span >x</motion.span>
              </motion.span>
              <motion.span className='last'>
                <motion.span >N</motion.span>
                <motion.span >O</motion.span>
                <motion.span >J</motion.span>
                <motion.span >I</motion.span>
                <motion.span >M</motion.span>
                <motion.span >A</motion.span>
              </motion.span>
            </motion.div>
        </motion.div>
        </div>
    );
}