import { Item } from "./Item";
import { List } from "./List";
import {AnimatePresence } from "framer-motion";

export function Store({ match,items,authors}) {
    let { id } = match.params;
    const imageHasLoaded = true;
    let item = items.find(item => item.slug === id);
    return (
      <>

                <div className="body " style={{margin:'auto',width:'100%'}}>
                  <List selectedId={id} items={items} authors={authors}/>
             {/* Morning wakeups */}
                  {id && imageHasLoaded && item &&<AnimatePresence>
                   <Item  items={items} id={id} authors={authors.find(auth => auth.fullName === item.author)} key="item" />
                  </AnimatePresence>}
                </div>
  
      </>
    );
  }
  
