import React from "react";
import '../style/header.scss';
const Header = () => {
  return (
    <header>
      <div className='container'>
        <div className='row space-between'>
          <div className='logo'>
            <a href='/'><img className="logoimg" src={process.env.PUBLIC_URL +'/logo.png'} alt="logo"/></a>
          </div>
          <div className='menu'><a href="/blogs"><span>BLOGS</span></a></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
