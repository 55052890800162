import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-image";
import '../style/home.scss'
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

// function Home({ imageDetails, image,link }){ 

function Home({ imageDetails, image,link}){ 

  const [url, setUrl] = useState("https://i.imgur.com/6Npegn1.jpg");
  useEffect(()=>{
    if (window.innerWidth<1300){
      setUrl("https://i.imgur.com/6Npegn1h.jpg");
    }  
    if (window.innerWidth<800){
      setUrl("https://i.imgur.com/6Npegn1l.jpg");
    } 
    if (window.innerWidth>1400){
      setUrl("https://i.imgur.com/6Npegn1.jpg");
    } 
      // eslint-disable-next-line
  },[window.innerWidth])

  return (
  <>
    <main>
      <div className='container'>
        <div className='row center'>

          <motion.div className='image-container'
            initial={{ opacity: 0, y: 100 }}
            animate={{
              opacity: 1,
              y: 0
              }}
              transition={{duration:2}}
            >
            <div
              className='thumbnail'
              ref={image}
              style={{
                width: imageDetails.width,
                height: imageDetails.height,
              }}>
              <motion.div className='frame'
                        style={{overflow:'hidden'}}
                        initial={{ opacity: 0, y: 30 }}
                        animate={{
                          opacity: 1,
                          y: 0
                          }}
                          transition={{duration:1.2,delay:0.3}}
                        >
                {link&&<>
                <Link to={`/blogs`}>
                  <ProgressiveImage
                    src={url}
                    placeholder="compressed-image.png"
                    >
                    {(src) => (
                      <motion.img
                        src={src}

                        alt='yuji'
                        whileHover={{ scale: 1.1 }}
                        transition={transition}
                        
                      />
                    )}
                  </ProgressiveImage>
                </Link>
                <motion.div className="tapabove"

                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0
                    }}
                    transition={{duration:1.2,delay:1}}
                  ><motion.p
                  animate={{
                   translateY: [0, -10, 0, -10, 0],
                 }}
                 transition={{
                   duration: 4,
                   ease: "easeInOut",
                   loop: Infinity,
                 }}>🧡 tap above :3 🧡</motion.p></motion.div>

                </> }
                {/* HAS NOT LOADED */}
                {!link&&<>
                  <div class="ipl-progress-indicator position" id="ipl-progress-indicator">
                    <div class="ipl-progress-indicator-head">
                      <div class="first-indicator"></div>
                      <div class="second-indicator"></div>
                    </div>
                    <div class="insp-logo-frame">
                      <div class="insp-logo-frame-img position">
                      <ProgressiveImage
                    src={url}
                    placeholder="compressed-image.png"
                    >
                    {(src) => (
                      <motion.img
                        src={src}
                        alt='yuji'
                        whileHover={{ scale: 1.1 }}
                        transition={transition}
                        
                      />
                    )}
                  </ProgressiveImage>
                  <h1 class="loading-message">🦍 bear with me!</h1>
                  
                    </div>
                    
                    </div>
                  </div>
                  
                  </>}
              </motion.div>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              
              className='information'>
                
              <div className='location'>
                <span>-23.683700</span>
                <span>133.904059</span>
              </div>
              <div className='insta'><span>@YUJI06NO</span></div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </main>
  </>
)};

export default Home;
