import React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import '../style/footernormal.scss';

//   var phantom = {
//   display: 'block',
//   padding: '10px',
//   height: '50px',
//   width: '100%',
//   }

function Footernormal() {
    let variable = "medium";
    if (window.innerWidth>760){
        variable = "large";

    }
    return (
        <div className="foot" style={{backgroundColor: "#c9b9ac",
        bottom:0}}>
        {/* <div style={phantom} /> */}
        <div className="innerfoot">
            <div className="logofoot">
                <img src={process.env.PUBLIC_URL +'/logo.png'} alt="logo"/>
            </div>
            <div className="links" >
                <a href="https://www.youtube.com/channel/UCyFV2tJxD4ofR-fnjJ_0kuw" className="firstlog"><YouTubeIcon style={{ fill: '#80470e' }}fontSize={variable}/></a>
                <a href="https://www.instagram.com/yuji06no/?hl=en" className="middlelog"><InstagramIcon style={{ fill: '#80470e' }}fontSize={variable}/></a>
                <a href="https://github.com/Yujio5017"><GitHubIcon style={{ fill: '#80470e' }}fontSize={variable} /></a>
            </div>
        </div>
    </div>

    );
}

export default Footernormal;