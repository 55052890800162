import '../style/Seenext.scss';
import React, {useEffect,useState} from "react";
function SeeNext({allblogs,items}) {

    const [previous, setPrev] = useState({slug:"",title:""});
    const [next,setNext] = useState({slug:"",title:"coming soon"});


    function findNext(){
            for (var i=0;i<allblogs.length;i++){
            //   if (allblogs[i].slug===items.slug){
                
              if (allblogs[i].slug===items){
                  if (i-1>=0){
                    setPrev(allblogs[i-1]);
                  }
                  if (i+1<allblogs.length){
                    setNext(allblogs[i+1]);
                  }
                  return;
                }
            }
            return null;
    }

    useEffect(()=>{
        findNext();
         // eslint-disable-next-line
      },[])

    return (
        <div className="seecontain" id="foot">
            <h1 className="seeHead">Read more</h1>
                <div className="seeinner">
                    <div className="seeauthor">
                        <div>
                            {previous&&(<>
                                <a href={`/blogs/${previous.slug}`}>
                                <img width="150px" style={{borderRadius:'20px'}}src={previous.photo} alt={previous.photoAlt}/><br/>
                                {previous.title}<br/>
                                {previous.author}
                                </a>
                            </>
                        )}
                        </div>
                        <div>
                            {next&&(<>
                                <a href={`/blogs/${next.slug}`}>
                                <img width="150px" style={{borderRadius:'20px'}} src={next.photo} alt={next.photoAlt}/><br/>
                                {next.title}
                                {/* {next.author} */}
                                </a>
                            </>
                        )}
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default SeeNext;