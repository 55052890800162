import React,{useState} from "react";
import { motion} from "framer-motion";
import Model from '../pages/model';
import '../style/blogs.scss'
import AboutMain from "../components/aboutmain";
import { Store } from "../store";
import { Featured } from "../featured";
// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import BackBack from "../components/backback";
import LazyLoad from "react-lazyload";
// const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
function Blogs({items,authors, imageDetails,quote,match,photo})
{
  const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
  const [selectPhoto,setSelectPhoto] = useState(false)
  const [selectCard,setSelectCard] = useState({})
  // const tail = ([, ...t]) => t;
  function selectedPhoto(card){
    setSelectPhoto(true);
    setSelectCard(card);
    var elmnt = document.getElementById("photoOpen");
    if (elmnt){
      elmnt.scrollIntoView({behavior: "smooth"});
    }

    // if (selectPhoto===false){
    //   setSelectCard({});
    // }
  }
  var last=items[items.length - 1];
  var itemsw=items.filter(blog=>blog!==last);
  return(
  <>
    <main className="blogmainbig">
      <BackBack/>
      <Model imageDetails={imageDetails}/>
        <div className='container'>
          <div className='blogtop'>
            <motion.div className='model'>
              <motion.div className="sailormickle"
              >
                <motion.span className='belowhero'
              initial={{
                  y:-500,
                  opacity:0,
            }}
              animate={{
                  y:0,
                  opacity:1,
              }}
              >
              {/* {headerStyle()} */}

              </motion.span>
              </motion.div>

              <div className="body " style={{margin:'auto',width:'100%',zIndex:3}}>

              <motion.h1 className="quotehome"
                            initial={{
                              y:50,
                              opacity:0,
                        }}
                          animate={{
                              y:0,
                              opacity:1,
                              transition: { type: "tween",delay: 1.2,duration:1 },
                              
                          }}
                          >{quote.text}<br/> — {quote.author}</motion.h1>

              <h1 className="bigblogheader" id="blog">Most Recent</h1>
                <div className="highlighted" style={{height:"10px",background:"var(--main-color)"}}/>

                <Featured items={last} match={match} imageDetails={imageDetails} authors={authors}/>

              <h1 className="bigblogheader" id="blog">Blogs</h1>
                <div className="highlighted" style={{height:"10px",background:"var(--main-color)"}}/>
                          
                <Store items={itemsw} match={match} imageDetails={imageDetails} authors={authors}/>

              </div>


            </motion.div >
            <AboutMain/>

            <h1 className="bigblogheader" id="photoOpen" >Moments</h1>
            <div className="highlighted moment" style={{height:"10px",background:"var(--main-color)"}}/>
            {selectPhoto&&<motion.div layoutId="head" className="gah open" onClick={()=>setSelectPhoto(false)}
                            initial={{
                              y:0,
                        }}
                          animate={{
                              y:50,
                              transition: { type: "tween",delay: 1.2,duration:1 },
                              
                          }}
            >
              <div style={{textAlign:'right',marginRight:'-5vw'}}>
                <CloseIcon/>
              </div>
                          <motion.div  className="frame"
                          >
                    <LazyLoad>

                          <motion.img layoutId="img"drag
  dragConstraints={{ left: 30, right: 30 }}
                          width="100%"src={selectCard.photo}
                          />
                    </LazyLoad>

                          </motion.div>

                          <motion.div className="body" >
                            <h1 className="title">{selectCard.description}</h1>
                            <div className="highlighted" />
                            <p className="time">{selectCard.time}</p>
                            <p className="content">{selectCard.content}</p>
                          </motion.div>
            </motion.div>}
            
            <div className="photographysquare">
            {photo.map(card => (
        <div key={card._id}>
                {(selectCard._id!==card._id)&&
                  <motion.div className="psquare">
                    <LazyLoad offset={800} height={'100%'}>
                <motion.img onClick={()=>selectedPhoto(card)}
                  whileHover={{scale:1.1}}
                  transition={transition}
                  width="100%"src={card.photo}/>
                  </LazyLoad>
                  </motion.div>
                }
                {(selectCard._id===card._id)&&
                  <div>
                  <motion.div className="psquare selected">
                    <LazyLoad>
                <motion.img onClick={()=>selectedPhoto(card)}
 
                
                  whileHover={{scale:1.1}}
                  transition={transition}
                  width="100%"src={card.photo}/>
                  </LazyLoad>
                  </motion.div>
                  <motion.div className="outline"layoutId="outline" 
                  style={{textAlign:'center'}}
                  initial={false}
                  > </motion.div>
                  </div>
                }

             </div>
              ))}

         
   

           </div>

           <div style={{margin:'60px 20px', textAlign:'center'}}>
             <h1 className="bigblogheader">Reviews</h1>
              {window.outerWidth>800&& <img src={process.env.PUBLIC_URL +"/comments.png"} width="100%" alt ="yujikanji"/>
              }<h1>⭐⭐⭐⭐⭐</h1>
            </div>
            {/* <div> */}
              {/* <h1>description</h1> */}
            {/* </div>            */}
            {/* </div> */}

            </div>
            </div>
    </main>
    </>
  );
};



export default Blogs;