import React, { useState } from 'react';
import { motion,AnimatePresence } from 'framer-motion';
import '../style/aboutmain.scss'
// import { Link } from "react-router-dom";
export default function AboutMain() {
    const [hasClicked,setHasClicked]=useState(false);
  const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

    function OpenAbout(){
        return(
            <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        transition={{ duration: 0.02, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay about"
        onClick={()=>setHasClicked(!hasClicked)}
      >

    <motion.div layoutId="orangie" style={{width:'100%',background:'#DE5A24',height:'30vh'}}/>

      <motion.div layoutId="showie" className="middlise showcaseabout " />

      </motion.div>
            <div className="splitmid  opened"  onClick={()=>setHasClicked(!hasClicked)}>
            <motion.div className="aboutimage" layoutId="aboutimage">
                <motion.img 
                width="70%"src={process.env.PUBLIC_URL +'/yuji_2f.jpg'}/>
              
              </motion.div>
             <div className="aboutcontainer">
                <motion.div className="abouthead" layoutId="abouthead">
                <h1 >
                  <u>Qualifications</u>
                  </h1>
                  <p className="abouttext">
                    University of Melbourne, Bachelors of Science, Major Computer Science 
                  </p>
                  <motion.p animate>
                      <b>Highschool</b>: St Philips College - Sports Captain, Vice House Captain, Prefect, First place Japanese, First place Physical Education
                      <br/>
                      <b>Work Roles</b> - Programs Officer YMCA, Porter/Hospitality Crowne, Junior Software Engineer HutSix
                      <br/>
                      <b>Volunteering</b> - U18 Head Coach - Scorpions FC (Premier winner), International Student Ambassador of UMSU, University of Melbourne BScience Peer Mentor
                      <br/><br/>
                      <b>Current Goal</b> - Planche, Regular Blogs, Finish reading <i> The 48 Laws of Power</i>
                  </motion.p>
                </motion.div>
              </div>


             </div>
            </>
        )
    }
        return (
            <>
            <motion.div layoutId="showie" className="middlise showcaseabout " onClick={()=>setHasClicked(!hasClicked)} id="photos" >
              <div className="aboutcontainer">
                <motion.div className="abouthead" layoutId="abouthead">
                <h1 >
                  <u>About me</u>
                  </h1>
                  <p className="abouttext">
                    University of Melbourne student, Athlete, Blogger, Full-time human. Just looking to improve myself.
                  </p>
                </motion.div>
              </div>

              <motion.div className="aboutimage"  layoutId="aboutimage">
                <motion.img 
                whileHover={{scale:1.1}}
                transition={transition}
                width="100%"src={process.env.PUBLIC_URL +'/yujiprofile.jpg'}/>
              </motion.div>
              <motion.div layoutId="orangie" className="orangieo"/>

            </motion.div>
            <AnimatePresence>
              {hasClicked&&<OpenAbout/>}
            </AnimatePresence>
            </>
        );
    }
