import React from "react";


import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./styles.scss"

export function Card({ slug, categories, title, photo,content,author,publishDate }) {
  // function scrollToTop(){
  //   window.scrollTo(0, 0);
  // onClick={scrollToTop}
  // }
  // let route = `${route}/${slug}`;
  // if (show==0){
  let  route=`/blogs/${slug}`;
  // }
  // console.log(route)
  const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
  return (
    <motion.li className={`card ${slug}`} key={slug}
    whileHover={{scale:1.03}}
    transition={transition}
    >

      {/* container pop */}
      <div className="card-content-container"
    >


        <motion.div className="card-content"  layoutId={`card-container-${slug}`}
        >          
        {/* style={{backgroundColor:`${author.colour}`}} */}
          {/* image pop */}
          <motion.div
    className="card-image-container"
            layoutId={`card-image-container-${slug}`}
          >
            <img className="card-image" src={photo} alt="" 
    style={{borderRadius:'30px'}}
    />
          </motion.div>
          {/* image pop end*/}
          
          {/* title pop */}
          <motion.div
            className="title-container"
            layoutId={`title-container-${slug}`}
          >
            <span className="category">{categories}</span>
            <h2>{title}</h2>
            <p className="author" style={{color:'white',fontSize: '12px',paddingTop:'0px'}}>@{author.fullName} {author.emoji} </p>

          </motion.div>
          {/* title pop end */}

        </motion.div>
      </div>
      {/* container pop end*/}
     
      <Link to={route} className={`card-open-link`} />
    </motion.li>
  );
}

export function List({ selectedId,items,authors,show,route }) {
  let itemsort = items; 
  // if (showing>0){
    // itemsort=items.slice(showing);
  // }

  function findAuthor(authors,author){
        for (var i=0;i<authors.length;i++){
          if (authors[i].fullName===author){
            return authors[i];
          }
        }
        return null;
}
  return (
    <>
    <div style={{textAlign:'center',marginBottom:'-50px'}}>
          {/* <h1 style={{color:"black",textAlign:'center',margin:0,fontSize:'6vh'}}></h1> */}
     </div>
    <ul className="card-list">
      {itemsort.map(card => (
        <Card key={card.slug} {...card} author={findAuthor(authors,card.author)} isSelected={card.id === selectedId} />
      ))}
        {/* <div style={{textAlign:'center',gridColumn:"1 /span 12",marginTop:'-25px'}}>
          <h1 style={{color:"black",textAlign:'center'}}>3rd May 2020</h1>
        </div> */}
    </ul>
    </>
  );
}
