import { ItemF } from "./ItemF";
// import { List } from "./List";
import {AnimatePresence } from "framer-motion";
import {Card} from "./List";
export function Featured({ match,items,authors}) {
  function findAuthor(authors,author){
        for (var i=0;i<authors.length;i++){
          if (authors[i].fullName===author){
            return authors[i];
          }
        }
        return null;
}
    let { id } = match.params;
    let value = false;
    if (items.slug===id) value = true;

    return (
      <>
 <ul className="card-list" style={{paddingTop:'0px',paddingBottom:'0px'}}>
                <div className="body " style={{margin:'auto',width:'100%'}}>
                <Card key={items.slug} {...items} author={findAuthor(authors,items.author)} isSelected={items.id === id} />
  
                  <AnimatePresence>
                    {id && value && <ItemF items={items} id={id} authors={authors.find(auth => auth.fullName === items.author)} key="item" />}
                  </AnimatePresence>
                </div>
  </ul>
      </>
    );
  }
  