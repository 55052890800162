import * as React from "react";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import ProgressiveImage from "react-progressive-image";
import '../style/home.scss'
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
// import { ProgressRound } from "../components/progressround";

const PageNotFound = () => (
  <>
    <main>
      <div className='container'>
        <div className='row center'>
         <span style={{transform:'translateY(-120px)',textAlign:'center',margin:'30px'}}>
           <h1 style={{margin:'10px'}}>
             <motion.div 
             animate={{
                translateY: [10, -20, 10, -10, 10],
              }}
              transition={{
                duration: 4,
                ease: "easeInOut",
                loop: Infinity
              }}
              >
                
               <EmojiSymbolsIcon style={{color:"#DE5A24",fontSize:'90px'}} />
               </motion.div>
               <br/>404 Page not found sorrryyy</h1><br/><br/>my app go brrr</span>
         </div>
      </div>
    </main>
  </>
);

export default PageNotFound;
