import React, {useEffect,useState} from "react";
import {AnimateSharedLayout} from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from 'axios';
//Pages
import Home from "./pages/home";
import Model from "./pages/model";
import Blogs from "./pages/blogs";
import PageNotFound from "./pages/404";
import Subscribed from "./pages/subscribed";
import Create from "./pages/createBlog";
//components
import Header from "./components/header";
import { Store } from "./store";
import Actions from "./components/actions";
import Footernormal from "./components/footernormal";
//Styles
import "./style/App.scss";
import Blogposted from "./pages/blogposted";



function App() {
 
  let variable= (650/524);
  const [size, setSize] = useState(window.innerWidth)
  const [footer,setFooter] = useState(0);
  const [quoteOne,setQuoteOne] = useState([]);
  const [isMounted,setMount]=useState(false);
  const [items,setItems]=useState([]);
  const [url, setUrl] = useState("https://i.imgur.com/6Npegn1.jpg");
  const [imageDetails, setImage] = useState({width:size,height:(size*variable),url:"https://i.imgur.com/6Npegn1.jpg"});

  const [authors,setAuthors]=useState([]);
  
  
  const [photos,setPhotos]=useState([]);
  const [fetched,setFetched]=useState(false);
  // Get items from database 
  const fetchBlogs=async () =>{
      axios.get('https://yujinojimabackend.onrender.com/all/')
      .then(res => {
      const itemsdata = res.data;
      setAuthors(itemsdata.authors);
      setItems(itemsdata.blogs.filter(validAuthor));
      setPhotos(itemsdata.photography)
      setFetched(true);
      })
  }
function validAuthor(blog){
  console.log(authors.find(auth => auth.fullName === blog.author))
  if (blog.author && authors.find(auth => auth.fullName === blog.author) === undefined){
    return blog;
  } else{
    return false;
  }
}
     // fake authentication Promise
  function authenticate(){
    return new Promise(function(resolve){
      setTimeout(resolve, 1000);
    }); // 2 seconds
  }


  function getQuotes() {
    fetch("https://type.fit/api/quotes")
    .then(function (response) {
      return response.json()
    })
    .then((data) => {
      setQuoteOne(data[Math.floor(Math.random() * data.length)])
    })
  }


  useEffect(()=>{
    if (!isMounted){
      fetchBlogs();
      getQuotes();
      setFooter(0);  
    authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 100)
      }
    })
    console.log("fetching...");
    setMount(true);
    
  }
      // eslint-disable-next-line
    },[])

useEffect(()=>{
    window.addEventListener("resize", updateWidth);

      let inner=(window.innerWidth*variable*0.6);
      if (size<1265){
        setImage({width:window.innerWidth*0.7,height:inner});
        } else{
        setImage({width:1024,height:750});
        }
      console.log("size update...");
      if (window.innerWidth<1300){
        setUrl("https://i.imgur.com/6Npegn1h.jpg");
      }  
      if (window.innerWidth<800){
        setUrl("https://i.imgur.com/6Npegn1l.jpg");
      } 
      if (window.innerWidth>1700){
        setUrl("https://i.imgur.com/6Npegn1.jpg");
      } 
        // eslint-disable-next-line
      },[window.innerWidth])
    const updateWidth = () => {
      setSize(window.innerWidth)
  }

  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  return (
    <Router>
      <Header       
      showNav={showNav}
      onHideNav={handleHideNav}
      onShowNav={handleShowNav}/>
      <Route
        render={({ location }) => (
          // <AnimatePresence initial={false} exitBeforeEnter>
          <AnimateSharedLayout type="crossfade">

            <Switch location={location} key={location.pathname}>


              {/* landing page */}
              <Route exact path='/' render={() => 
                <>
                {setFooter(0)}
                <Home imageDetails={imageDetails} link={true} />
                </>}
              />
              
              {/* home page */}
              <Route exact path='/home/:id' render={() => 
                <>
                {setFooter(1)}
                <Model imageDetails={url} />
                </>
                }
              />

              {/* photo page */}
              {/* <AnimateSharedLayout type="crossfade"> */}
              <Route path={["/blogsall/:id", "/blogsall/"]} render={({match}) => 
                  <>
                  {fetched&&<Store items={items} match={match} imageDetails={imageDetails} authors={authors} show={0}/>
              }</>
                  }/>
              {/* blog page */}
              {/* {items &&<Route exact path="/blogs/:id" render={({match})=>(
                <>
                {setFooter(1)}
                  {findBlogs(items,authors,match)}
                    </>
              )}/>
              } */}

              {/* blogs page  */}
              {setFooter(1)}
              {/* <AnimateSharedLayout type="crossfade"> */}
              <Route exact path={["/blogs/:id", "/blogs/"]}  render={({match}) => 
                  <div>
                 {fetched===true ? <>
                                    <Blogs link={false} photo={photos} match={match} items={items} quote={quoteOne} authors={authors} imageDetails={imageDetails}/>
                                  </>: <Home imageDetails={imageDetails}/> 
    
              }</div>
                  }
                />
              {/* </AnimateSharedLayout> */}
              <Route exact path={["/subscribed"]}  component={Subscribed}/>
              <Route exact path={["/blogposted"]}  component={Blogposted}/>

              <Route exact path={["/writeblog"]}  component={Create}/>

               <Route component={PageNotFound} />



                </Switch>
              {/* // </AnimatePresence> */}
    </AnimateSharedLayout>

        )}
      />
      
      {footer&&<Footernormal/>}
      <Actions/>

    </Router>
  );
}

export default App;
