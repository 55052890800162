import React, {useRef,useEffect,useState} from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ProgressiveImage from "react-progressive-image";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//Components
// import ScrollForMore from "../components/scrollForMore";
import '../style/model.scss';
//Ease
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 1.5,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};
const lastName = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 1.7,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};


const Model = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.85]);
  const [url, setUrl] = useState("https://i.imgur.com/6Npegn1.jpg");
  const [based, setBased] = useState({balancer:0,initialZ:1,
    xLift:-10,
    imageCH:350,
    imageH:20,
    dataH:50,
    namey:-400,
    nameyfin:-100});
  const [letter, setLetter] = useState({
    initial: {
      y: based.namey,
      opacity:0,
    },
    animate: {
      y: based.nameyfin,
      opacity:1,
      transition: { duration: 1, ...transition },
    },
  });
  // const scaletitle = useTransform(scrollYProgress, [0, 0.1], [0, 1]);
  // const scaletitle2 = useTransform(scrollYProgress, [0.3, 0.4], [0, 1]);
  // const scaletitle3 = useTransform(scrollYProgress, [0.6, 0.7], [0, 1]);

  // const [canScroll, setCanScroll] = useState(false);

  const middle = {
    initial: {
      y: 0,
    },
    animate: {
      y: based.xLift,
      transition: {
        delayChildren: 2,
        staggerChildren: 0.04,
        staggerDirection: -1,
      },
    },
  }
  const constraintsRef = useRef(null)
  const imageConstraintsRef = useRef(null)

  
// const letterHover ={
    //   translateY:-60,
    // }
    useEffect(() => {
      function updateValues(){
    var basedd = {balancer:0,initialZ:1,
      xLift:-10,
      imageCH:350,
      imageH:20,
      dataH:50,
      namey:-400,
      nameyfin:-100};

  if (window.innerWidth<500){
    // location start    
    basedd.balancer=20;
    basedd.imageH=150;
    basedd.namey=200;
    basedd.nameyfin=300;
  } else if (window.innerWidth<770){
    basedd.balancer=40;
    basedd.initialZ=1;
    basedd.dataH=50;
    basedd.namey=300;
    basedd.nameyfin=200;
    basedd.imageH=70;
    basedd.imageCH=300;
  }else if (window.innerWidth<1070){
    basedd.balancer=40;
    basedd.initialZ=1;
    basedd.dataH=50;
    basedd.nameyfin=200;
    basedd.imageH=250;
    basedd.imageCH=600;
  }else if (window.innerWidth<1370){
    basedd.balancer=40;
    basedd.initialZ=1;
    basedd.dataH=50;
    basedd.nameyfin=-100;
    basedd.imageH=-150;
    basedd.imageCH=800;
  }else if (window.innerWidth<=1600){
    basedd.balancer=70;
    basedd.namey=400;
    basedd.nameyfin=-150;
    basedd.dataH=150;
    basedd.imageH=-100;
    basedd.imageCH=800;
  } else {
    // location start    
    basedd.namey=400;
    // above
    basedd.nameyfin=-150;
    // beggining
    basedd.balancer=20;
    // location text
    basedd.dataH=350;
    // image height from start
    basedd.imageH=-150;
    // bottom border
    basedd.imageCH=1200;
  }
  setBased(basedd);
  setLetter({
    initial: {
      y: basedd.namey,
      opacity:0,
    },
    animate: {
      y: basedd.nameyfin,
      opacity:1,
      transition: { duration: 1, ...transition },
    },
  }); 

}
function updateUrl(){
  if (window.innerWidth<1300){
    setUrl("https://i.imgur.com/6Npegn1h.jpg");
  }  
  if (window.innerWidth<800){
    setUrl("https://i.imgur.com/6Npegn1l.jpg");
  } 
  if (window.innerWidth>1400){
    setUrl("https://i.imgur.com/6Npegn1.jpg");
  } 
}
window.addEventListener("resize", updateValues);
window.addEventListener("resize", updateUrl);
    }, []);
    
  // console.log(balancer);
  return (
    <>

  <motion.div
      // onAnimationComplete={() => setCanScroll(true)}
      className='single'
      initial='initial'
      animate='animate'
      exit='exit'
      >
      <div className='container fluid'>
        <motion.div className='row center top-row' ref={constraintsRef}>
          <motion.div className='top' >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: based.dataH,
                transition: { delay: 1.2, ...transition },
              }}
              className='detailss'>
              <motion.div className='location'
               animate={{
                translateY: [0, -10, 0, -10, 0],
              }}
              transition={{
                duration: 4,
                ease: "easeInOut",
                loop: Infinity,
              }}>
                <motion.span drag='x' dragConstraints={constraintsRef}>-23.683700</motion.span>
                <motion.span drag='x' dragConstraints={constraintsRef}>133.904059</motion.span>
              </motion.div>
              <motion.span style={{transform:'translateY(-50px)',position:'absolute'}}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{
                              opacity: 1,
                              y: -60,
                              transition: { delay: 3, ...transition },
                              duration:1
                            }} >
                              <motion.div >
              <span>an interactive blog.</span>
              </motion.div></motion.span>
              <motion.div className='insta'
              animate={{
                translateY: [0, -10, 0, -10, 0],
              }}
              transition={{
                duration: 4,
                ease: "easeInOut",
                loop: Infinity,
              }}><motion.span className="instamove"
               >@YUJI06NO</motion.span>
              </motion.div>

            </motion.div>
            <motion.div className='model'>
              <motion.span className='first'  variants={firstName} >
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>Y</motion.span >
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>U</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>J</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>I</motion.span>
              </motion.span>
              <motion.span className='middle' variants={middle}>
              
              <motion.span drag variants={letter} dragConstraints={constraintsRef}>x</motion.span>
              </motion.span>
              <motion.span className='last' variants={lastName}>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>N</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>O</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>J</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>I</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>M</motion.span>
                <motion.span drag variants={letter} dragConstraints={constraintsRef}>A</motion.span>
              </motion.span>

            </motion.div>
            {/* <motion.div className='mod' style={{textAlign:'center'}}>
            

            </motion.div> */}
          </motion.div>
        </motion.div>
        <div className='row bottom-row'>
          <div className='bottom'>
            <motion.div className='image-container-single'>
              <motion.div
                initial={{
                  y: "-50%",
                  width: imageDetails.width,
                  height: imageDetails.height+based.balancer,
                }}
                animate={{
                  y: 0,
                  width: "100%",
                  height: based.imageCH,
                  transition: { delay: 0.2, ...transition },
                }}
               

                className='thumbnail-single'>
                <motion.div
                  className='frame-single'
                  whileHover={{ translateY:70,type:"spring" }}
                  transition={transition}
                  ref={imageConstraintsRef}
                  >
                    <motion.div    drag="y"
                      dragConstraints={imageConstraintsRef}
                      >
                  <ProgressiveImage
                    src={url}
                    placeholder="compressed-image.png">
                    {(src, loading) => (
                      <motion.img
                      src={src}
                      alt='yuji'
                      style={{ scale: scale}}
                      initial={{ scale: based.initialZ }}
                      animate={{
                        transition: { delay: 0.2, ...transition },
                        y: based.imageH,
                      }}
                      />
                    )}
                  </ProgressiveImage>
                  <motion.div className="pull-me primarytext" animate={{
                  y: based.imageH*0.8,delay:1}}>
                    <ExpandMoreIcon/>
                  </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
          {/* <ScrollForMore /> */}
        </div>
      </div>
    </motion.div>
    </>
  );
};

export default Model;
