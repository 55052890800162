import '../style/About.scss';
import React from "react";
function About({author}) {
    return (
        <div className="aboutcontain" id="foot">
                <div className="aboutinner">
                {author&&(<>
                    <h1 className="aboutHead">About the Author </h1>
                    {/* <div className="aboutauthor"> */}
                
                    <img style={{ borderRadius: "25px"}} src={author.photo} alt={author.photoAlt}></img>
                    <span className="authorName"> {author.emoji} {author.fullName}</span>
                    <p><span className="adesc">❤️ Authors notes: {author.description}</span></p>
                    {/* </div> */}
                    
                </>)}
                </div>
        </div>
    );
}

export default  About;