import React from "react";
import { motion } from "framer-motion";
import '../style/actions.scss';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CreateIcon from '@material-ui/icons/Create';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
export default function Actions() {
    const [lastYPos, setLastYPos] = React.useState(0);
    const [shouldShowActions, setShouldShowActions] = React.useState(false);
  
    React.useEffect(() => {
      function handleScroll() {
        const yPos = window.scrollY;
        const isScrollingUp = yPos < lastYPos;
  
        setShouldShowActions(isScrollingUp);
        setLastYPos(yPos);
      }
  
      window.addEventListener("scroll", handleScroll, false);
  
      return () => {
        window.removeEventListener("scroll", handleScroll, false);
      };
    }, [lastYPos]);

    function scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
    }
    function scrollToMiddle(){
        var elmnt = document.getElementById("blog");
        elmnt.scrollIntoView({behavior: "smooth"});
    }
    function scrollToLast(){
        var elmnt = document.getElementById("photos");
        elmnt.scrollIntoView({behavior: "smooth"});
    }

    return (
      <motion.div
        className="actions"
        initial={{ opacity: 0 }}
        animate={{ opacity: shouldShowActions ? 1 : 0 }}
        transition={{ opacity: { duration: 0.2 } }}
      >
        <button onClick={() => scrollToTop()}>
          <ExpandLessIcon/>
        </button>
        <button onClick={() => scrollToMiddle()}>
          <CreateIcon/>
        </button>
        <button onClick={() => scrollToLast()}>
          <CameraAltIcon/>
        </button>
      </motion.div>
    );
}