
import React,{useState} from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom'

import '../style/createBlog.scss';
export function MyForm() {
  const [formData, setFormData] = useState({});
  const handleChange = (event) => {
      setFormData({...formData, [event.target.id]: event.target.value});
    }
  
    const history = useHistory();
    const routeChange = (path) => {
        history.push(path);
    }
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      // submit to the DB
      try {
          // Prevent the default reloading of page
          event.preventDefault();
  
          const res = await axios.post("https://hidden-bastion-71905.herokuapp.com/addBlog", formData);
          
          if (res !== undefined){
              // And send the user to the home page
              routeChange(`/blogposted`);
          }
      }
      catch (error) {
      }
  }
  //onSubmit={this.printSearch} 
    return (
      <form className="yujiform" onSubmit={handleSubmit} method="POST">
        <h1 className="yujititle" >CREATE YOUR BLOG</h1>
        <p >Here you can upload your blog, make sure everything is correct, it will appear as you type it. Thanks for writing!</p>
        

        <h2>Title of your blog</h2>
        <input
          onChange={handleChange}
          name='title'
          id="title"
          for="title"
          placeholder="Mr yuji is my king"
        />
        <h2>slug (the link so eg /i-love-yuji)</h2>
        <input
          onChange={handleChange}
          name='slug'
          id='slug'
          placeholder="mr-yuji-is-my-king"
        />
        <h2>small description of your blog</h2>
        <input
          onChange={handleChange}
          name='description'
          id='description'
          placeholder="i worship yuji daily he rocks a rockabye"
        />
        <h2>photo link (if u a have a link else tell yuji to update)</h2>
        <input
          onChange={handleChange}
          name='photo'
          id='photo'
          placeholder="https://i.imgur.com/BlyJS4L.png"
        />
        <h2>photo description</h2>
        <input
          onChange={handleChange}
          name='photoAlt'
          id='photoAlt'

          placeholder="yuji and his peasants"
        />
        <h2>content of your blog</h2>
        <p>bold encase in &#60;b\&#62; <b>such brackets</b> &#60;/b&#62;</p>
        <p>enter encase in &#60;\n&#62;</p>
        <p>double enter encase in &#60;\n&#62; &#60;\n&#62;</p>
        <p>italicise encase in &#60;i\&#62; <i>such brackets</i> &#60;/i&#62;</p>
        <p>Don't worry too much yuji can edit for you again</p>
        <input
          className="p-2 mt-10"
          onChange={handleChange}
          name='content'
          id='content'

          placeholder="Day one /n /n the first <b>offering</b>"
        />
      
        <h2>author (your full name, capitalise first letters, must be already an author, else will crash the website)</h2>
        <input
          onChange={handleChange}
          name='author'
          id='author'
          placeholder="Yuji Nojima"
        />
        <h2>publish date</h2>
        <input
          onChange={handleChange}
          name='publishDate'
          id='publishDate'

          placeholder="3rd May 2021"
        />
        <h2>category</h2>
        <input
          onChange={handleChange}
          name='catergories'
          id='catergories'

          placeholder="life advice"
        />
        <br/>
        <br/>
      <button type='submit' className='yujisubmit' value="Submit">
          Submit
        </button>
      </form>
      
    );
  }

function Create(){

    return(
      <div className="container" style={{padding:"70px 100px"}}>
        <MyForm/>
      </div>
    );
}


export default Create